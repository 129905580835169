import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";

import Navbar from "../../components/navbar";
import Login from "../login";
import SectorMap from "../sectormap";

import './styles.css';

export default function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Navbar></Navbar>
        <div className="routeResult">
            <Routes>
                <Route path="/" element={<SectorMap/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/sector" element={<SectorMap/>}>
                  <Route path="system/:systemId" element={<SectorMap/>}/>
                  <Route path="location/:locationId" element={<SectorMap/>}/>
                </Route>
            </Routes>
        </div>
        </Router>
    </div>
  );
}
