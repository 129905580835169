import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode'

import { authenticationService, userTokenData } from '../../_services'
import './styles.css'

interface TokenData {
    username: string,
    discriminator: string,
    role: string[],
    id: string,
    exp: number
}

export default function Login() {
    let location = useLocation()
    let navigate = useNavigate();
    const [code,setCode] = useState("waiting")

    useEffect(()=> {
        let code = new URLSearchParams(location.search).get('code')
        console.log(code)
        fetch('https://farverona.com/api/login',{
            method:'post', 
            headers:{
                'Content-Type': 'application-json'
            },
            body: JSON.stringify({'code': code})
        }).then(data=>{
            return data.json()
        }).then(data=>{        
            console.log(data)
            setCode("Data received, rerouting...")
            let info: TokenData = jwt(data["token"])
            let user: userTokenData = { 
                "token": data["token"],
                "user": jwt(data["token"]),
                "name": info["username"]+"#"+info["discriminator"]
            }
            console.log(info, user)
            authenticationService.login(user)
            if(authenticationService.currentUserValue["name"]) {
                navigate('/')
            }            
        }).catch(error => console.log('error', error))
    },[location, navigate])

    return (
        <div className="MenuOption-root">
            Code: {code}
        </div>
    )
}

