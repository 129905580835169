import { useParams } from 'react-router';

import { Sector } from '../../components/map'
import './styles.css';

export default function SectorMap() {
  let { locationId, systemId } = useParams();
  return (
    <div className="LoggedOff">
      <Sector locationId={locationId} systemId={systemId}/>
    </div>
  );
}
