import { useNavigate } from 'react-router';
import './styles.css';
import { systemInterface } from '../../_services';
import { useEffect, useState } from 'react';

interface SystemInterface {
  systemId: string
  systems: systemInterface[]
}

export function System({systemId, systems}: SystemInterface): JSX.Element { 
  const [system, setSystem] = useState<systemInterface>() 
  const navigate = useNavigate()
  useEffect(
    ()=>{setSystem(systems.find(s => s.id === systemId))}
    ,[])
  function hideInfo() {
    navigate("/sector")
  }
  return (
    <div>
      <div className='overlay_systeminfo' onClick={hideInfo}></div>
      <div className="systeminfo" onClick={hideInfo}>
        <div className="systeminfo_name">{system?.Name.toUpperCase()}</div>
        <div className="systeminfo_desc">{system?.desc}</div>
        {system?.planets?.map(p=><div className="systeminfo_planet">
                                  <div className="systeminfo_planet_name">{p.Name}</div>
                                  <div className="systeminfo_planet_desc">{p.desc}</div>
                                </div>)}
    </div>
    </div>

  );
}
