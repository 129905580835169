import { BehaviorSubject } from 'rxjs';

export interface userTokenData {
    token: string,
    user: {
        exp: number,
        username: string,
        user: string,
        discriminator: string,
        id: string,
        roles: string[]
    },
    name?: string
}

export const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser') || "{}"));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(user: userTokenData) {
    // add user to local storage to log user in
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
