export interface systemInterface {
    id:string,
    x:string,
    y: string,
    Name: string,
    stations?:{
            Name: string,
            desc?: string,
            satellites?: {Name: string, type?: string}[]
        }[]
    planets?:{
            Name: string,
            desc?: string,
            satellites?: {Name: string, type?: string}[]
        }[],
    desc: string

}

function gfs(): systemInterface[] {
    return [
        {
            "id":"1",
            "x":"0",
            "y":"0",
            "Name":"Almu Gerbia",
            "planets":[
                {
                    "Name":"Stakklakou",
                    "desc":"A relatively developed technological civiliation lives here."
                }
            ],
            "desc":"A system out of range of Imperial control."
        },
        {
            "id":"5",
            "x":"0",
            "y":"4",
            "Name":"Ragnhil",
            "planets":[
                {
                    "Name":"Koschei",
                    "desc":"Larger of the two inhabited planets in the system, and the homeworld of The Deathless."
                },
                {
                    "Name":"Thorg",
                    "desc":"Formerly a prosperous world, events during the Second Imperial Civil War caused it to become the low-technology feral world it is today."
                }
            ],
            "desc":"The system remains the home of the Deathless Conflict Solutions mercenary group.",
        },
        {
            "id":"6",
            "x":"0",
            "y":"5",
            "Name":"Phalest",
            "planets":[
                {
                    "Name":"Chippe",
                    "desc":"Being one of the furthest and least interesting corners of the Empire, it is believed to be the origin of the phrase `backwater`."
                }
            ],
            "desc":"The system that's been colonzed by Imperial citizens for ages."
        },
        {
            "id":"8",
            "x":"0",
            "y":"7",
            "Name":"Oenon",
            "planets":[
                {
                    "Name":"Briolette (Zerculous)",
                    "desc":"This world was marginal for human habitation when it was discovered, the terraforming did not entirely work. The natives are only partly adapted to the world's current state, and struggle with the environment."
                },
                {
                    "Name":"Cabochon (Halvei)",
                    "desc":"This world has the technological sophistication, natural resources, and determined polity necessary to be a regional hegemon for the sector."
                }
            ],
            "desc":"The system is home to the oceanworld Halvei and the barely habited world Zerculous. Prior to the scream, the Oenon star husbandry platform, controlled and regulated the system's star for a forgotten purpose."
        },
        {
            "id":"9",
            "x":"0",
            "y":"8",
            "Name":"Pavvis",
            "planets":[
                {
                    "Name":"Eurynoc (Ascher)",
                    "desc":"The locals have maintained sufficient Mandate-era tech to be effectively post-scarcity in their economic structure. However, central planning nodes direct all production and employment on the world. On this world a flavor of communism actually works."
                },
                {
                    "Name":"Marquise (Mona)",
                    "desc":"Home to a Psionic Academy, the only known academy in Acheron Rho outside Hroa."
                }
            ],
            "desc":"The system is on the far reaches of Acheron Rho and beyond direct Imperial control."
        },
        {
            "id":"14",
            "x":"1",
            "y":"1",
            "Name":"Iphoos",
            "planets":[
                {
                    "Name":"Leorgioly",
                    "desc":"Known for it's extraterrestial population."
                },
                {
                    "Name":"Thorhil",
                    "desc":"An oceanworld known for it's seagoing cities."
                }
            ],
            "desc":"A system out of range of Imperial control."
        },
        {
            "id":"15",
            "x":"1",
            "y":"2",
            "Name":"Eunelas",
            "planets":[
                {
                    "Name":"Hong Lu",
                    "desc": "Former criminal hub, now a secondary planet of House Triangulum"
                }
            ],
            "desc":"The sector seen many battles during the Hong Lu revolt persiod and was home to synth factories of House Cygnus."
        },
        {
            "id":"26",
            "x":"2",
            "y":"5",
            "Name":"Pamita Cha",
            "planets": [
                {
                    "Name":"Lovelace",
                    "desc":"A green poinsonous planet, uninhabited due to the corrosive atmosphere. Riddled with temporary research stations.",
                    "satellites":[
                        {
                            "Name":"Habitat One",
                            "type":"station"
                        }
                    ]
                }
            ],
            "desc":"The home system of the noble house of science, House Triangulum."
        },
        {
            "id":"29",
            "x":"2",
            "y":"2",
            "Name":"Eriopei",
            "planets":[
                {
                    "Name":"Echo",
                    "desc":"Homeworld of the PRISM Network"
                }
            ],
            "desc":"The system is home to the PRISM Network"
        },
        {
            "id":"35",
            "x":"2",
            "y":"9",
            "Name":"Igliza",
            "planets":[
                {
                    "Name":"Trillia IX",
                    "desc": "Trilliant Ring homeworld"
                }
            ],
            "desc":"Home to Trillia IX, the Trilliant Ring homeworld."
        },
        {
            "id":"41",
            "x":"3",
            "y":"3",
            "Name":"Amedere Orbere",
            "planets":[
                {
                    "Name":"Teuthem",
                    "desc":"Known for it's only city Shaelthum, protected by the Shield of Light from the intrusive fungi. Governed by the Confederation of the Upright Vagrant from the Arcadian Hall."
                }
            ],
            "desc":"Current hotbed of faction activity surrounding Vagrant Fleets, The Deathless, and The PRISM Network."
        },
        {
            "id":"44",
            "x":"3",
            "y":"6",
            "Name":"Sig",
            "planets":[
                {
                    "Name":"Andophael",
                    "desc": "The central world of the High Church of the Emperor Messiah"
                }
            ],
            "desc":"The most culturally significant system of the Empire after Imperial Prime. Hosts Andophael, the Holy Planet"
        },
        {
            "id":"46",
            "x":"3",
            "y":"8",
            "Name":"Helesco",
            "planets":[
                {
                    "Name":"Navette (Berkmann III)",
                    "desc":"Navette, formerly Berkmann III, was restored to the Empire in 3200 by the Trilliant Ring"
                }
            ],
            "desc":"Nicknamed 'The Crossroads' Halesco acts as a conduit and supply stop between the Imperial worlds and the galactic south. "
        },
        {
            "id":"50",
            "x":"4",
            "y":"0",
            "Name":"Osun",
            "planets":[
                {
                    "Name":"Kama",
                    "desc":"A decadent pleasure world littered with castoff Guild facilities and objet de fuq."
                }
            ],
            "desc":"Far in the north, the system is only used for pleasure and research on it's bizzarre inhabitants."
        },
        {
            "id":"56",
            "x":"4",
            "y":"6",
            "Name":"Alvero",
            "planets":[
                {
                    "Name":"Aomori",
                    "desc":"The subtropical homeworld of House Reticulum known for its beautiful blue forests.",
                    "satellites":[
                        {
                            "Name":"Phio",
                            "type":"moon"
                        }
                    ]
                },
                {
                    "Name":"Tiber",
                    "desc":"The tropical homeworld of House Eridanus known for its many rivers that drain into purple seas."
                },
                {
                    "Name":"Cobalt",
                    "desc":"A gas giant owned by House Reticulum, it contains the headquarters of the Kaze Korporation."
                },
                {
                    "Name":"Foros",
                    "desc":"A gas giant and the farthest planet from the sun in its system."
                }
            ],
            "desc":"Although a home to two of the Noble Houses, the system itself does not consist of many special features"
        },
        {
            "id":"57",
            "x":"4",
            "y":"7",
            "Name":"Tsatsos",
            "planets":[
                {
                    "Name":"Demnoph",
                    "desc":"Former A.C.R.E. Homeworld, now owned by House Eridanus."
                },
                {
                    "Name":"Hroa",
                    "desc":"House Serpens Homeworld, location of The Academy"
                },
                {
                    "Name":"Yakiyah",
                    "desc":"Former Unified People's Collective's Homeworld"
                }
            ],
            "desc":"One of the core systems of the Empire, home to House Serpens and House Eridanus."
        },
        {
            "id":"67",
            "x":"5",
            "y":"5",
            "Name":"Imperial Prime",
            "planets":[
                {
                    "Name":"Maja",
                    "desc":"House Fornax Homeworld"
                },
                {
                    "Name": "Imperial Prime",
                    "desc": "The Imperial capital world"
                }
            ],
            "desc": "The capital system of the Empire, now in control of House Pyxis."
        },
        {
            "id":"68",
            "x":"5",
            "y":"6",
            "Name":"Aliya",
            "planets":[
                {
                    "Name":"Diomikato",
                    "desc": "House Aquila homeworld"
                },
                {
                    "Name":"Yimia",
                    "desc": "An airless, rocky world utterly inhospitable to human habitation or colonization."
                },
                {
                    "Name":"Ichilles",
                    "desc": "The planet has a thin atmosphere, but a powerful magnetic field prevents solar radiation from stripping it away."
                },
                {
                    "Name":"Soarus",
                    "desc": "Soarus is an airless, barren world. It’s cold surface has been home to settlements, mining facilities, and other void based commercial ventures for many centuries."
                },
                {
                    "Name":"Lilea",
                    "desc": "A swirling haze of gases, the massive planet contains a number of moons and man-made stations within its sphere of influence"
                },
                {
                    "Name":"Demetrios",
                    "desc": "Demetrios is a ice giant. Composed of a mixture of oxygen, nitrogen and sulfur."
                }
            ],
            "desc":"Home system to House Aquila"
        },
        {
            "id":"71",
            "x":"5",
            "y":"9",
            "Name":"Benilli",
            "planets":[
                {
                    "Name":"Cabina",
                    "desc":"SERAPH homeworld"
                },
                {
                    "Name":"Adela",
                    "desc":"A small rocky planet, tidally locked with Benilli."
                },
                {
                    "Name":"Mangi",
                    "desc":"Mangi, is a toxic world."
                },
                {
                    "Name":"Ravat",
                    "desc":"Ravat is a rocky, cold, low gravity, and cloudless world."
                },
                {
                    "Name":"Prora",
                    "desc":"Prora is an ice world"
                },
                {
                    "Name":"Garudra",
                    "desc":"aka Pilgrim's Rest"
                },
                {
                    "Name":"Mookambodar",
                    "desc":"Mookambodar is most famous for its beautiful rings."
                },
                {
                    "Name":"Surunis",
                    "desc":"Surunis is an unremarkable gas giant. It has twelve moons."
                },
                {
                    "Name":"Kateshana",
                    "desc":"Unremarkable gas giant. It has four moons."
                },
                {
                    "Name":"Ledilesi",
                    "desc":"An ice world among ice worlds."
                },
            ],
            "desc":"Holds Cabina, the homeworld of SERAPH and former homeworld of the Church of Humanity, Repentant."
        },
        {
            "id":"75",
            "x":"6",
            "y":"1",
            "Name":"Xandare",
            "planets":[
                {
                    "Name":"Bora",
                    "desc":"Protected by an advanced defensive net system aimed to take down any ships that come in range."
                },
                {
                    "Name":"Pylopid",
                    "desc":"Pylopid is a planet of dual natures, one calm, and one violent."
                }
            ],
            "desc":"No Imperial influence except for some small Triangulum presence."
        },
        {
            "id":"79",
            "x":"6",
            "y":"5",
            "Name":"Ellis",
            "planets":[
                {
                    "Name":"Hiera",
                    "desc":"House Crux Homeworld."
                },
                {
                    "Name":"Gats",
                    "desc":"House Cygnus Homeworld."
                },
                {
                    "Name":"Joly",
                    "desc":"A large gas giant, boasts at least twelve natural satellites."
                }
            ],
            "desc":"Home to House Cygnus and House Crux."
        },
        {
            "id":"80",
            "x":"6",
            "y":"6",
            "Name":"Perithr",
            "planets":[
                {
                    "Name":"Haqani",
                    "desc":"Homeworld of House Vela, location of the great library (now destroyed)"
                }
            ],
            "desc":"It was home to the House Vela, the Great Library Server and the College of Navigation."
        },
        {
            "id":"84",
            "x":"6",
            "y":"10",
            "Name":"Hild",
            "planets":[
                {
                    "Name":"Shān",
                    "desc": "Home to the Vagrant Fleet"
                },
                {
                    "Name":"Argiast",
                    "desc":"Not much is known off-world of Argiast. Human population is hostile to visitors."
                }
            ],
            "desc":"The system that supposedly wa origin to the Vagrant Fleets."
        },
        {
            "id":"85",
            "x":"6",
            "y":"11",
            "Name":"Pujaya Nita",
            "planets":[
                {
                    "Name":"Hallger",
                    "desc":"The world regularly suffers some apocalyptic catastrophe that wipes out organized civilization on it."
                }
            ],
            "desc":"Outside of Imperial jurisdiction."
        },
        {
            "id":"86",
            "x":"7",
            "y":"0",
            "Name":"Selequon",
            "planets":[
                {
                    "Name":"Ergus",
                    "desc": "Some historians postulate that the planet was under the rule of House Vela pre-Scream, who established the Preceptor Archive as backup system for the Great Library."
                }
            ],
            "desc":"The ice planet of Ergus hosts anti-imperial groups."
        },
        {
            "id":"90",
            "x":"9",
            "y":"4",
            "Name":"Home",
            "planets":[],
            "stations":[
                {
                    "Name":"Lodestone",
                    "desc":"House Pyxis homeworld"
                }
            ],
            "desc":"An almost empty system that hosts Lodestone, a pretech station that can shift the spike pathways around it."
        },
        {
            "id":"91",
            "x":"7",
            "y":"5",
            "Name":"Antakis",
            "planets":[
                {
                    "Name":"Orpheus",
                    "desc":"House Lyra Homeworld."
                }
            ],
            "desc":"The home system of House Lyra"
        },
        {
            "id":"102",
            "x":"8",
            "y":"4",
            "Name":"Tavroular",
            "planets":[
                {
                    "Name":"Yanaratso",
                    "desc":"Presence of Zombies confirmed."
                }
            ],
            "desc":"Hosts a zombie planet."
        },
        {
            "id":"106",
            "x":"8",
            "y":"8",
            "Name":"Guild HQ",
            "planets":[],
            "desc":"This system is occupied by the whimsical deadly Guild."
        },
        {
            "id":"111",
            "x":"9",
            "y":"1",
            "Name":"Meneus",
            "planets":[
                {
                    "Name":"Ias",
                    "desc":"Known for bubble cities and Cygnus and Triangulum influences."
                },
                {
                    "Name":"Man",
                    "desc":"Planet Man is at a glance an uninhabitable wasteland, but hosts a robust underground biosphere."
                }
            ],
            "desc":"Formerly influenced by the Empire."
        },
        {
            "id":"112",
            "x":"9",
            "y":"2",
            "Name":"Jel Vaa",
            "planets":[
                {
                    "Name":"Gleipnir",
                    "desc":"Imperial corrections facility"
                }
            ],
            "desc":"Contains an Imperial prison planet."
        },
        {
            "id":"113",
            "x":"9",
            "y":"3",
            "Name":"Askirut Sal",
            "planets":[
                {
                    "Name":"Phymete VIII",
                    "desc":"The world is under a quarantine, and space travel to and from it is strictly forbidden."
                }
            ],
            "desc":"Minor House Lyra influence."
        },
        {
            "id":"121",
            "x":"9",
            "y":"11",
            "Name":"Bergunn",
            "planets":[
                {
                    "Name":"Al-Dost",
                    "desc":"Known for aminalistic-humanoid inhabitants and pretech ruins."
                },
                {
                    "Name":"Chrates",
                    "desc":"The planet is populated mostly by scions of the original settlers and ruled by an oligarchy of the Frostnax"
                },
                {
                    "Name":"Pierus XXIII",
                    "desc":"The local government is brutal and indifferent to the will of the people."
                },
            ],
            "desc":"Hosts ancient civilisations, has recent influence of the Vagrant Fleets."
        },
    ]
}

export const map = {
    getHexes: () => {
        let hexes = [];
        let width = 60
        let height = 22
        let hex = [-10, -10];
        for (let i = -28; i <= width; i++) {
            for (let j = -10; j <= height; j++) {
                hex = [i, j];
                hexes.push(hex);
            }
        }
        return hexes;
    },
    getFakeSystems: gfs
  }