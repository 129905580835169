import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { map, systemInterface } from '../../_services'
import { System } from './'
import './styles.css';

interface SectorInterface {
  locationId?: string;
  systemId?: string;
}

export function Sector({systemId, locationId}: SectorInterface): JSX.Element {  
  const [systems, setSystems] = useState<systemInterface[]>([])
  const navigate = useNavigate()
  function hideInfo() {
    return
  }

  useEffect(()=>{
    setSystems(map.getFakeSystems());
  },[])

  function showInfo(elem: any) {
    navigate('/sector/system/'+elem["id"])
  }

  function svgSystems() {
    let systems = map.getFakeSystems();
    return (
        <g>
            {systems.map((elem)=> {
                return (
                    <svg key={(+elem.x > 9?'':'0')+ +elem.x.toString()+(+elem.y > 9?'':'0')+ +elem.y.toString()}
                     x={(+elem.x * 22.5)} y={((+elem.x%2)*13)+(+elem.y*26)}>
                        <polygon className="system" points="30,15 22.5,28 7.5,28 0,15 7.5,2 22.5,2"
                        onClick={()=>showInfo(elem)}>            
                        </polygon>
                        {/* <circle className="planet" cx="15" cy="15" r="2"></circle>  */}
                        <text className="small" x="15" y="25" dominantBaseline="middle" textAnchor="middle">
                            {(+elem.x > 9?'':'0')+ +elem.x.toString()+(+elem.y > 9?'':'0')+ +elem.y.toString()}
                        </text>    
                    </svg>
                )
            })}
        </g>     
    )
}

  function svgHexes() {
    let hexes = map.getHexes();
    return (
        <g>
            {hexes.map((elem)=> {
                return (
                    <svg key={"k"+elem[0]+"-"+elem[1]} x={(elem[0] * 22.5)} y={((elem[0]%2)*13)+(elem[1]*26)}>
                        <polygon className="hex" points="30,15 22.5,28 7.5,28 0,15 7.5,2 22.5,2" onClick={hideInfo}>
                        </polygon>
                    </svg>
                )
            })}
        </g>     
    )
}

  return (
    <div className="svg-container">
      <svg id="color-fill" xmlns="http://www.w3.org/2000/svg"  version="1.1" viewBox="0 0 285 400" height="100%" width="100%">
        <g transform="translate(25,25)">
            {svgHexes()}
            {svgSystems()}          
        </g>
      </svg>
      {systemId?<System systemId={systemId} systems={systems}/>:null}
    </div>
  );
}
